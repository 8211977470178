<template>
    <div>
        <b-modal id="edit-plugin-modal" hide-footer size="md" centered title="Add Plugin" @hidden="resetModal">
            <div class="modal_content">
                <b-alert dismissible :show="hasError" class="p-1" variant="danger" @dismissed="clearErrorMsg">{{
                    errorMsg }}</b-alert>
                <p class="margin-for-title">Title*</p>
                <b-form-input class="mb-1" v-model="data.title" placeholder="Example: Drag And Drop" type="text" />
                <p class="margin-for-title">Code*</p>
                <b-form-input class="mb-1" v-model="data.pluginName" placeholder="Example: DragAndDrop" type="text" /> 
                <div class="mt-2">
                    <p class="margin-for-title">Platform*</p>
                    <b-form-group>
                        <div 
                            class="d-flex flex-row justify-content-between align-items-center" 
                            :style="{ width: '70%' }"
                            >
                            <b-form-radio 
                                v-for="option in Platforms" 
                                :key="option.value" 
                                v-model="data.platform"
                                :value="option.value" 
                                class="formRadio">
                                {{ option.label }}
                            </b-form-radio>
                        </div>
                    </b-form-group> 
                </div>  

                <template>
                    <p class="margin-for-title"> Path*</p>
                    <b-form-input class="mb-1" v-model="data.path" placeholder="Plugin path" type="text" :disabled="data.platform == 'Mobile'"/>
                </template>
                <div class="modal-footer">
                    <b-button @click="updatePlugin"
                     :disabled="loading" variant="primary">
                        <b-spinner v-if="loading" small class="mr-1" />
                        Save Plugin
                    </b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import {
    BButton,
    BFormInput,
    BFormGroup,
    BAlert,
    BSpinner,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
} from "bootstrap-vue";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
    components: {
        BButton,
        BFormInput,
        BFormGroup,
        BAlert,
        BSpinner,
        BDropdownItem,
        ToastificationContent,
        BFormRadioGroup,
        BFormRadio,
    },
    data() {
        return {
            themeName: "",
            baseVuePath: "@view/src/views/components/themes/",
            baseReactNativePath: "@src/components/themes/",
            vuePath: "@view/src/views/components/themes/",
            reactNativePath: "@src/components/themes/",
            errorMsg: "",
            loading: false,
            alertMessageToast: "",
            hasError:false,
            data: {
                title: "",
                pluginName: "",
                path: "",
                platform: "Mobile",
            },
            Platforms: [
            { value: "Mobile", label: "Mobile" },
                { value: "Remote Web", label: "Remote Web" },
                { value: "Local Web", label: "Local Web" }
            ],
        };
    },
    computed: {
        // hasError() {
        //     return this.errorMsg != "";
        // },
    },
    watch: {
        theme: function (newVal) {
            this.data.title = this.theme.title;
                this.data.pluginName= this.theme.plugin;
                this.data.path= this.theme.path;
                this.data.platform= this.theme.platform;
        },
       'data.platform'(val){ 
        if(val == 'Mobile'){
        this.$set(this.data, 'path', '');
      }
     }
    },
    methods: {

   async updatePlugin() {
    let isValidated = this.validateData();

    this.loading = true;
    if (isValidated) {
        try {
            const response = await axios.put(
                `${process.env.VUE_APP_API_URL}/plugin/update-plugin/${this.theme._id}`,  
                this.data,  
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("_t")}`,  
                    },
                }
            );

            if (response.data.success) { 
                this.alertMessageToast = response.data.message || 'Data updated successfully';
                this.showToast("success");
                this.$emit('fetchPlugin');

            } else { 
                this.alertMessageToast = 'Error updating data!';
                this.showToast("danger");
            }
        } catch (error) { 
            if (error.response && error.response.status == 422) {
                this.alertMessageToast =  error.response.data.message || 'validation error';
                this.showToast("danger");
            } else{
                this.alertMessageToast = 'An error occurred while updating data!';
                this.showToast("danger");
            }
           
        } finally {
            this.loading = false;
            this.formData = {
                title: "",
                pluginName: "",
                path: "",
                platform: 'Mobile',
            };
            this.$bvModal.hide('edit-plugin-modal');
            
        }
    } else {
        this.loading = false;
    }
},

validateData() { 
      this.errorMsg = '';
      this.hasError = false;
      const trimmedTitle = this.data.title && this.data.title.trim();
      
      if (!trimmedTitle) {
        this.errorMsg = 'Title cannot be empty or just spaces';
        this.hasError = true;
        return false;
      }
 
      this.data.title = trimmedTitle;
      const trimmedPluginName = this.data.pluginName&&this.data.pluginName.trim();
 
      if (!trimmedPluginName) {
        this.errorMsg = 'Please fill all required fields';
        this.hasError = true;
        return false;
      }

      if (trimmedPluginName.includes(' ')) {
        this.errorMsg = 'Spaces are not allowed in the code';
        this.hasError = true;
        return false;
      }
      this.data.pluginName = trimmedPluginName

      if (this.data.platform != 'Mobile' && !(this.data.path&&this.data.path.trim())) {
        this.errorMsg = 'Please fill all required fields';
        this.hasError = true;
        return false;
      } 

      this.data.path = this.data.path&&this.data.path.trim()
      return true;
    },

        showToast(variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Notification",
                    icon: "BellIcon",
                    text: this.alertMessageToast,
                    variant,
                },
            });
        },
        
        clearErrorMsg() {
            this.errorMsg = "";
        },
        resetModal() {
            this.themeName = "";
            this.errorMsg = "";
            this.$emit('removeThemeData');
        },
        
    },
    props: ["theme"],
};
</script>
